<template>
  <v-container style="width: 100%" class="mt-md-16 mt-8">
    <div v-if="validated" class="d-flex text-md-h4 text-h5 text-center justify-center mb-md-16 mb-10">
      <div class="d-flex flex-column align-center">
        <v-img max-width="70" contain src="../assets/done-800px.png"></v-img>
        <div class="pt-8">
          Vielen Dank für Ihre Verifizierung.<br />
          Wir haben Ihnen eine E-Mail mit der Buchungsbestätigung gesendet.
          <br />
          Dies kann einige Minuten in Anspruch nehmen.
        </div>
      </div>
    </div>
    <div v-if="errored" class="d-flex text-md-h4 text-h5 text-center justify-center mb-md-16 mb-10">
      <div class="d-flex flex-column align-center">
        <v-img max-width="70" contain src="../assets/done-800px.png"></v-img>
        <div class="pt-8">
          Es ist leider ein Fehler unterlaufen.<br />
          Bitte laden sie die Seite erneut oder kontaktieren sie den Support.<br />
          info@corona-test-point.de
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      validated: false,
      errored: false,
    };
  },

  mounted() {
    this.$http({
      method: "post",
      url: "/test/validate",
      data: {
        testIdentifier: this.$route.params.token,
      },
    })
      .then(() => {
        this.validated = true;
      })
      .catch((err) => {
        this.errored = true;
        console.log("AXIOS ERROR: ", err);
      });
  },
};
</script>
